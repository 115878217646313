<template>
  <div
    @click="clickUser"
    class="card card-body me-3 mb-3 position-relative pb-0">
    <h4 class="card-title">{{ user.first_name }} {{ user.last_name }}</h4>
    <p class="card-text">
      {{ user.username }}
      {{ user.email }}
    </p>
    <div class="position-absolute bottom-0 end-0 btn-group" v-if="$route.name === 'Users'">
      <button
        class="btn btn-link"
        @click="$store.commit('mergeStore', { usersItem: user })"
        data-bs-toggle="modal"
        data-bs-target="#userModal">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info" viewBox="0 0 16 16">
          <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
        </svg>
      </button>
    </div>
    <div class="position-absolute top-0 end-0 d-flex">
      <div v-if="user.is_staff || user.is_superuser" class="badge bg-indigo text-white fs-12 me-1">
        Админ
      </div>
      <div class="badge bg-cyan text-dark fs-12">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
          <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: Object
  },
  methods: {
    clickUser () {
      if (this.$route.name === 'Tag') {
        if (this.$store.state.tag.user_ids.includes(this.user.id)) {
          this.$toast.error('Отключение пользователя от тега')
        } else {
          this.$toast.success('Добавление пользователя в тег')
        }
        this.$socket.emit('tag_user', {
          tag_id: this.$store.state.tag.id,
          user_id: this.user.id,
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.card{
  .btn-group{
    visibility: hidden;
  }
  &:hover{
    cursor: pointer;
    .btn-group{
      visibility: visible;
    }
  }
}
</style>
