<template lang="html">
  <div class="container pt-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'Index' }">Панель</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Пользователи
        </li>
      </ol>
    </nav>
    <div class="d-flex mb-3">
      <h3>Пользователи</h3>
    </div>

    <div class="d-flex flex-wrap">
      <UserItem
        v-for="(user, i) in $store.state.users"
        :key="i"
        :user="user"/>
    </div>

    <div
      class="modal fade"
      id="userModal"
      tabindex="-1"
      aria-labelledby="titleUserModal"
      aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="titleUserModal">Пользователь системы</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            {{ $store.state.usersItem }}
          </div>
          <div class="modal-footer">
            <!-- <button class="btn btn-primary me-3" @click="$socket.emit('accounts', $store.state.account)">Добавить</button> -->
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Закрыть</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import UserItem from '../components/UserItem'
export default {
  name: 'Users',
  components: {
    UserItem
  },
  async mounted () {
    try {
      if (this.$store.state.user.is_staff) {
        this.$socket.emit('get_data', ['users'])
      }
    } catch (e) {
      console.error(e)
      this.$toast.error(`${e.type}: ${e.message}`)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
